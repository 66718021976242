import { ChevronDown, ChevronUp } from 'lucide-react';
import React, { useEffect, useState } from 'react';

interface CollapsibleSectionProps {
  title: React.ReactNode | string;
  children: React.ReactNode;
  defaultExpanded?: boolean;
  expandAll?: boolean;
  emptyCheck?: any;
  className?: string;
  variant?: 'default' | 'highlight';
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  children,
  defaultExpanded = true,
  expandAll,
  emptyCheck,
  className = "mb-6",
  variant = 'default'
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  useEffect(() => {
    if (expandAll !== undefined) {
      setIsExpanded(expandAll);
    }
  }, [expandAll]);

  if (!emptyCheck) {
    return null;
  }

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  const headerClasses = variant === 'highlight'
    ? "flex justify-between items-center py-4 px-6 bg-primary text-white dark:bg-primary cursor-pointer hover:bg-primary-dark dark:hover:bg-primary-dark transition-colors rounded-md"
    : "flex justify-between items-center py-4 px-6 bg-tesla-lightGray dark:bg-tesla-dark-surface cursor-pointer hover:bg-tesla-hover dark:hover:bg-tesla-dark-hover transition-colors rounded-md";

  const contentClasses = variant === 'highlight'
    ? "py-4 px-6 bg-primary/10 dark:bg-primary/20"
    : "py-4 px-6 bg-tesla-hover dark:bg-tesla-dark-bg";

  const titleClasses = variant === 'highlight'
    ? "font-gotham text-lg text-white dark:text-white"
    : "font-gotham text-lg text-tesla-black dark:text-tesla-dark-text";

  const chevronClasses = variant === 'highlight'
    ? "text-white dark:text-white"
    : "text-tesla-gray dark:text-tesla-dark-text/70";

  return (
    <div className={className}>
      <div
        className={headerClasses}
        onClick={toggleExpand}
      >
        {React.isValidElement(title) ? (
          title
        ) : (
          <h3 className={titleClasses}>{title}</h3>
        )}
        {isExpanded ? (
          <ChevronUp size={20} className={chevronClasses} />
        ) : (
          <ChevronDown size={20} className={chevronClasses} />
        )}
      </div>

      {isExpanded && (
        <div className={contentClasses}>
          {children}
        </div>
      )}
    </div>
  );
};

export default CollapsibleSection;
