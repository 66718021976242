import { useCallback, useEffect, useState } from 'react';
import { useApi, useAuthedApi } from './useApi';
import { EncounterData } from '../model/EncounterData';
import { SAMPLE_ENCOUNTER_DATA } from '../util/sampleData';

interface CheckEmailResponse {
  emailTaken: boolean;
}

interface RegisterResponse {
  accessToken: string;
}

interface RegisterData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

interface LoginResponse {
  accessToken: string;
  tokenType: string;
  user: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
  };
}

interface LoginData {
  email: string;
  password: string;
}

export function useCheckEmailApi() {
  const { callApi, error, data, isLoading } = useApi<CheckEmailResponse>();

  const checkEmailApi = useCallback(
    (email: string) => callApi(`check-email?email=${email}`),
    [callApi]
  );

  return { checkEmailApi, error, data, isLoading };
}

export function useRegisterApi() {
  const { callApi, error, data, isLoading } = useApi<RegisterResponse>();

  const registerApi = useCallback(
    (registerData: RegisterData) =>
      callApi('user', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(registerData),
      }),
    [callApi]
  );

  return { registerApi, error, data, isLoading };
}

export function useLoginApi() {
  const { callApi, error, data, isLoading } = useApi<LoginResponse>();

  const loginApi = useCallback(
    (loginData: LoginData) =>
      callApi('login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginData),
      }),
    [callApi]
  );

  return { loginApi, error, data, isLoading };
}

export interface Encounter {
  id: string;
  name: string;
  chiefComplaint?: string;
  description: string;
  data?: EncounterData;
  updatedAt: string;
}

export interface CreateEncounterData {
  name: string;
  description: string;
}

export function useCreateEncounterApi() {
  const { callApi, error, data, isLoading } = useAuthedApi<Encounter>();

  const createEncounterApi = useCallback(
    (createEncounterData: CreateEncounterData) =>
      callApi('encounter', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(createEncounterData),
      }),
    [callApi]
  );

  return { createEncounterApi, error, data, isLoading };
}

export function useEncounters() {
  const { callApi, error, data, isLoading } = useAuthedApi<Encounter[]>();

  const fetchEncounters = useCallback(() => callApi('encounter', { method: 'GET' }), [callApi]);

  return { fetchEncounters, error, data, isLoading };
}

export interface Message {
  id: string;
  role: 'user' | 'assistant';
  type: string;
  data: {
    content: string;
    promptIndex?: number;
  };
}

export function useEncounterMessages() {
  const { callApi, error, data, isLoading } = useAuthedApi<Message[]>();

  const fetchEncounterMessages = useCallback(
    (encounterId: string) => callApi(`message?encounter_id=${encounterId}`, { method: 'GET' }),
    [callApi]
  );

  return { fetchEncounterMessages, error, data, isLoading };
}

export function useEncounter() {
  const { callApi, error, data, isLoading } = useAuthedApi<Encounter>();
  const [dataWithSample, setDataWithSample] = useState<Encounter | null>(data);

  useEffect(() => {
    setDataWithSample(data);
  }, [data]);

  const fetchEncounter = useCallback(
    (encounterId: string) => {
      if (encounterId === 'test') {
        // Return the sample data immediately for the test encounter
        setDataWithSample(SAMPLE_ENCOUNTER_DATA);
        return Promise.resolve({ data: SAMPLE_ENCOUNTER_DATA, error: null, isLoading: false });
      }
      return callApi(`encounter/${encounterId}`, { method: 'GET' });
    },
    [callApi]
  );

  return { fetchEncounter, error, data: dataWithSample, isLoading };
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export function useCurrentUser() {
  const { callApi, error, data, isLoading } = useAuthedApi<User>();

  const fetchCurrentUser = useCallback(
    () => callApi('user/me', { method: 'GET' }),
    [callApi]
  );

  return { fetchCurrentUser, error, user: data, isLoading };
}

export function useUpdateEncounterApi() {
  const { callApi, error, data, isLoading } = useAuthedApi<Encounter>();

  const updateEncounterApi = useCallback(
    (encounterId: string, updateData: Partial<Encounter>) =>
      callApi(`encounter/${encounterId}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updateData),
      }),
    [callApi]
  );

  return { updateEncounterApi, error, data, isLoading };
}
