import React from 'react';
import { Encounter } from '../hooks/apis';
import CollapsibleSection from './CollapsibleSection';
import FollowUpCollapsible from './FollowUpCollapsible';
import KeyValuePair from './KeyValuePair';

interface PlanSectionsProps {
  encounter?: Encounter;
  expandAll?: boolean;
  checkedItems?: Record<string, boolean>;
  onCheckboxChange?: (id: string) => void;
}

const PlanSections: React.FC<PlanSectionsProps> = ({
  encounter,
  expandAll,
  checkedItems,
  onCheckboxChange,
}) => {
  const encounterData = encounter?.data;

  const checkboxClass = `
    appearance-none
    h-4 w-4 rounded border-tesla-black dark:border-tesla-dark-text border 
    checked:bg-tesla-gray/50
    checked:hover:bg-tesla-gray/50
    checked:border-tesla-gray/50
    focus:ring-tesla-black dark:focus:ring-tesla-dark-text focus:ring-1
    hover:border-tesla-black dark:hover:border-tesla-dark-text
    relative
    before:content-['✓']
    before:hidden
    checked:before:block
    before:absolute
    before:text-white
    before:text-xs
    before:top-0
    before:left-[2px]
  `;

  const checkedTextClass = 'text-tesla-gray/50 dark:text-tesla-dark-text/50';

  return (
    <>
      <CollapsibleSection
        title="High Yield Questions"
        expandAll={expandAll}
        emptyCheck={
          encounterData?.followUpQuestions?.some(q =>
            q.questionTopic?.trim() ||
            q.tipsToFocusOn?.trim() ||
            q.associatedDiagnoses?.some(d => d.trim())
          )
        }
      >
        <div className="mt-2">
          {encounterData?.followUpQuestions?.map((question, index) => (
            <FollowUpCollapsible
              key={index}
              item={question}
              expandAll={expandAll}
              type="question"
            />
          ))}
        </div>
      </CollapsibleSection>

      <CollapsibleSection
        title="High Yield Exam"
        expandAll={expandAll}
        emptyCheck={
          encounterData?.followUpPhysicalExam?.some(exam =>
            exam.finding?.trim() ||
            exam.tipsToFocusOn?.trim() ||
            exam.associatedDiagnoses?.some(d => d.trim())
          )
        }
      >
        <div className="mt-2">
          {encounterData?.followUpPhysicalExam?.map((exam, index) => (
            <div key={index}>
              <div className="flex items-center gap-2">
                <FollowUpCollapsible
                  key={index}
                  item={exam}
                  expandAll={expandAll}
                  type="exam"
                />
                <a
                  href={`https://www.youtube.com/results?search_query=${encodeURIComponent(exam.finding || '')}+physical+exam,short`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-tesla-gray hover:text-tesla-black dark:text-tesla-dark-text/70 dark:hover:text-tesla-dark-text transition-colors group relative"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l6 4.5-6 4.5z" />
                  </svg>
                  <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-tesla-black dark:bg-tesla-dark-text text-white dark:text-tesla-black px-2 py-1 rounded text-xs whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                    Watch exam technique videos
                  </span>
                </a>
              </div>
            </div>
          ))}
        </div>
      </CollapsibleSection>

      <CollapsibleSection
        title="Orders"
        expandAll={expandAll}
        emptyCheck={
          (encounterData?.labTestsSuggested?.some(test =>
            test.testName?.trim() ||
            test.associatedDiagnoses?.some(d => d.trim())
          )) ||
          (encounterData?.imagingStudiesSuggested?.some(study =>
            study.study?.trim() ||
            study.associatedDiagnoses?.some(d => d.trim())
          ))
        }
      >
        <div className="space-y-4">
          <div>
            <h4 className="font-semibold text-lg text-tesla-black dark:text-tesla-dark-text mb-2">Lab Tests</h4>
            {encounterData?.labTestsSuggested?.map((test, index) => (
              <div key={index} className="mb-2 flex items-start gap-2">
                <input
                  type="checkbox"
                  checked={checkedItems?.[`lab-${index}`] || false}
                  onChange={() => onCheckboxChange?.(`lab-${index}`)}
                  className={`mt-1 ${checkboxClass}`}
                />
                <div className={checkedItems?.[`lab-${index}`] ? checkedTextClass : ''}>
                  <p className="text-sm font-bold">{test.testName}</p>
                  <KeyValuePair
                    value={test.associatedDiagnoses?.join(', ')}
                    compact
                    textColor={checkedItems?.[`lab-${index}`] ? 'tesla-gray/50 dark:text-tesla-dark-text/30' : 'tesla-gray dark:text-tesla-dark-text/70'}
                  />
                </div>
              </div>
            ))}
          </div>

          <div>
            <h4 className="font-semibold text-lg text-tesla-black dark:text-tesla-dark-text mb-2">Imaging Studies</h4>
            {encounterData?.imagingStudiesSuggested?.map((study, index) => (
              <div key={index} className="mb-2 flex items-start gap-2">
                <input
                  type="checkbox"
                  checked={checkedItems?.[`imaging-${index}`] || false}
                  onChange={() => onCheckboxChange?.(`imaging-${index}`)}
                  className={`mt-1 ${checkboxClass}`}
                />
                <div className={checkedItems?.[`imaging-${index}`] ? checkedTextClass : ''}>
                  <p className="text-sm font-bold">{study.study}</p>
                  <KeyValuePair
                    value={study.associatedDiagnoses?.join(', ')}
                    compact
                    textColor={checkedItems?.[`imaging-${index}`] ? 'tesla-gray/50 dark:text-tesla-dark-text/30' : 'tesla-gray dark:text-tesla-dark-text/70'}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </CollapsibleSection>

      <CollapsibleSection
        title="CRITICAL ACTIONS"
        defaultExpanded={true}
        expandAll={expandAll}
        emptyCheck={
          encounterData?.criticalActions?.some(action =>
            action?.trim()
          )
        }
      >
        <ul className="list-none pl-5 text-gray-700 dark:text-tesla-dark-text">
          {encounterData?.criticalActions?.map((action, index) => (
            <li key={index} className="text-base flex items-center gap-2">
              <input
                type="checkbox"
                checked={checkedItems?.[`critical-${index}`] || false}
                onChange={() => onCheckboxChange?.(`critical-${index}`)}
                className={checkboxClass}
              />
              <span className={`font-bold ${checkedItems?.[`critical-${index}`]
                ? checkedTextClass
                : 'text-tesla-black dark:text-tesla-dark-text'
                }`}>
                {action}
              </span>
            </li>
          ))}
        </ul>
      </CollapsibleSection>

      <CollapsibleSection
        title="Clinical Decision Tools"
        expandAll={expandAll}
        emptyCheck={
          encounterData?.clinicalDecisionToolsSuggested?.some(tool =>
            tool.tool?.trim() ||
            tool.associatedDiagnoses?.some(d => d.trim())
          )
        }
      >
        <div className="space-y-2">
          {encounterData?.clinicalDecisionToolsSuggested?.map((tool, index) => (
            <div key={index} className="mb-2">
              <p className="text-tesla-black dark:text-tesla-dark-text text-sm font-bold">
                {tool.tool}
              </p>
              <KeyValuePair
                value={tool.associatedDiagnoses?.join(', ')}
                compact
                textColor="tesla-gray dark:text-tesla-dark-text/70"
              />
            </div>
          ))}
        </div>
      </CollapsibleSection>
    </>
  );
};

export default PlanSections; 