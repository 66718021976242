import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import KeyValuePair from './KeyValuePair';

interface FollowUpCollapsibleProps {
  item: {
    questionTopic?: string;
    finding?: string;
    tipsToFocusOn?: string;
    associatedDiagnoses?: string[];
    power?: string;
  };
  expandAll?: boolean;
  type: 'question' | 'exam';
}

const FollowUpCollapsible: React.FC<FollowUpCollapsibleProps> = ({
  item = {},
  expandAll,
  type
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const title = type === 'question' ? item?.questionTopic : item?.finding;

  useEffect(() => {
    if (expandAll !== undefined) {
      setIsExpanded(expandAll);
    }
  }, [expandAll]);

  return (
    <div className="border-b border-tesla-border dark:border-tesla-dark-border last:border-b-0">
      <div
        className="flex justify-between items-center py-3 px-4 cursor-pointer hover:bg-tesla-hover/30 dark:hover:bg-tesla-dark-border/20 transition-colors"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center gap-4">
          {isExpanded ? (
            <ChevronUp size={16} className="text-tesla-gray dark:text-tesla-dark-text/70" />
          ) : (
            <ChevronDown size={16} className="text-tesla-gray dark:text-tesla-dark-text/70" />
          )}
          <span className="font-gotham text-lg text-tesla-black dark:text-tesla-dark-text">{title}</span>
        </div>
      </div>

      {isExpanded && (
        <div className="py-3 px-4 pl-10 space-y-3 bg-tesla-hover/10 dark:bg-tesla-dark-surface/50">
          <KeyValuePair 
            label="Focus" 
            value={item.tipsToFocusOn} 
            labelColor="tesla-green dark:text-green-400"
          />
          <KeyValuePair 
            label="Diagnoses" 
            value={item.associatedDiagnoses?.join(', ')} 
            labelColor="tesla-purple dark:text-tesla-purple"
          />
          <KeyValuePair 
            label="Significance" 
            value={item.power} 
            labelColor="primary dark:text-primary"
          />
        </div>
      )}
    </div>
  );
};

export default FollowUpCollapsible; 