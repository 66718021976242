import React from 'react';
import { Link as ScrollableLink } from 'react-scroll';
import { ArrowRight, Stethoscope, Zap, MessageSquare, FileText, Globe } from 'lucide-react';
import ContactForm from '../components/ContactForm';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div id="top" className="min-h-screen font-sans dark:bg-tesla-dark-bg">
      {/* Navigation */}
      <nav className="bg-white dark:bg-tesla-dark-surface shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <span className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">Foundation.care</span>
              </div>
            </div>
            <div className="flex items-center">
              <Link 
                to="/home" 
                className="text-gray-700 dark:text-tesla-dark-text hover:text-blue-600 dark:hover:text-blue-400 px-3 py-2 rounded-md text-sm font-medium cursor-pointer"
              >
                Home
              </Link>
              {['Features', 'Team', 'Contact'].map((item) => (
                <ScrollableLink
                  key={item}
                  to={item.toLowerCase()}
                  smooth={true}
                  duration={500}
                  className="text-gray-700 dark:text-tesla-dark-text hover:text-blue-600 dark:hover:text-blue-400 px-3 py-2 rounded-md text-sm font-medium cursor-pointer"
                >
                  {item}
                </ScrollableLink>
              ))}
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="bg-gradient-to-br from-blue-600 via-blue-700 to-purple-700">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold text-white sm:text-5xl md:text-6xl">
              Empowering Doctors with Smart Decision Tools
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-blue-100 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Foundation.care provides cutting-edge clinical decision support tools to help doctors make informed choices and improve patient outcomes.
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <ScrollableLink
                  to="contact"
                  smooth={true}
                  duration={500}
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 md:py-4 md:text-lg md:px-10 cursor-pointer"
                >
                  Get Started
                  <ArrowRight className="ml-2 -mr-1 h-5 w-5" />
                </ScrollableLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div id="features" className="py-12 bg-gradient-to-b from-white to-blue-50 dark:from-tesla-dark-bg dark:to-tesla-dark-surface">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-blue-600 dark:text-blue-400 font-semibold tracking-wide uppercase">Features</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-tesla-dark-text sm:text-4xl">
              Revolutionizing Clinical Decision Support
            </p>
          </div>

          <div className="mt-10">
            <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-r from-blue-500 to-purple-500 text-white">
                    <Stethoscope className="h-6 w-6" />
                  </div>
                </div>
                <div className="ml-4">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">AI-Assisted Diagnosis & Treatment Plans</h3>
                  <p className="mt-2 text-base text-gray-500">
                    Generate and iteratively refine differential diagnoses and treatment plans, leveraging AI to analyze patient data and provide evidence-based suggestions.
                  </p>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-r from-blue-500 to-purple-500 text-white">
                    <Zap className="h-6 w-6" />
                  </div>
                </div>
                <div className="ml-4">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Constant Learning</h3>
                  <p className="mt-2 text-base text-gray-500">
                    Stay up-to-date with the latest medical research and guidelines, integrated seamlessly into your workflow.
                  </p>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-r from-blue-500 to-purple-500 text-white">
                    <MessageSquare className="h-6 w-6" />
                  </div>
                </div>
                <div className="ml-4">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Intelligent Scribe</h3>
                  <p className="mt-2 text-base text-gray-500">
                    Listen to patient communications and automatically transcribe conversations, saving time and improving accuracy.
                  </p>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-r from-blue-500 to-purple-500 text-white">
                    <FileText className="h-6 w-6" />
                  </div>
                </div>
                <div className="ml-4">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Beautiful Clinical Notes</h3>
                  <p className="mt-2 text-base text-gray-500">
                    Quickly generate clinical notes for a wide variety of specialities that are accurate, customized for each patient, comprehensive and beautifully written.
                  </p>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-r from-blue-500 to-purple-500 text-white">
                    <Globe className="h-6 w-6" />
                  </div>
                </div>
                <div className="ml-4">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Multilingual Patient Instructions</h3>
                  <p className="mt-2 text-base text-gray-500">
                    Create clear patient instructions in any language and at various reading levels, ensuring effective communication.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Founders Section */}
      <div id="team" className="bg-gradient-to-t from-blue-50 to-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Our Team</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Meet the Founders
            </p>
          </div>
          <div className="mt-10">
            <div className="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <div className="text-center">
                <div className="relative w-40 h-40 mx-auto">
                  <div className="absolute inset-0 bg-gradient-to-br from-blue-400 to-purple-400 rounded-full opacity-75 blur"></div>
                  <img src="/img/sunny.png" alt="Dr. Sunny Harris" className="relative mx-auto h-40 w-40 rounded-full object-cover" />
                </div>
                <div className="mt-4">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Dr. Sunny Harris</h3>
                  <p className="text-sm text-blue-600">CEO & Hacker</p>
                  <p className="mt-2 text-base text-gray-500">
                  A board-certified Emergency Medicine physician and visionary leader. Having personally witnessed the complexity and urgency of patient care in high-pressure ER environments, he recognized the need for instant access to vital medical data and streamlined clinical decision-making tools. Driven by a passion for technological innovation, Dr. Harris taught himself to code and transformed those insights into EMMA
                  </p>
                </div>
              </div>
              <div className="mt-10 md:mt-0 text-center">
                <div className="relative w-40 h-40 mx-auto">
                  <div className="absolute inset-0 bg-gradient-to-br from-blue-400 to-purple-400 rounded-full opacity-75 blur"></div>
                  <img src="/img/diana.png" alt="Dr. Diana Cortes, DDS" className="relative mx-auto h-40 w-40 rounded-full object-cover" style={{ objectPosition: '0px 0px' }} />
                </div>
                <div className="mt-4">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Dr. Diana Cortes, DDS</h3>
                  <p className="text-sm text-blue-600">Chief Medical Officer</p>
                  <p className="mt-2 text-base text-gray-500">
                  Drawing on extensive clinical expertise, she pairs her passion for dentistry with cutting-edge AI to elevate patient care and refine the way healthcare teams operate. Under her leadership, innovative technologies seamlessly integrate into practice, streamlining workflows, enhancing diagnostics, and optimizing patient outcomes. Dr. Cortes stands at the intersection of tradition and technology, guiding our organization into a new era of intelligent, compassionate care.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Section */}
      <div id="contact" className="bg-gradient-to-b from-white to-blue-50 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Contact Us</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Get in Touch
            </p>
          </div>
          <ContactForm />
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base text-gray-400">
              &copy; 2024 Foundation.care, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
